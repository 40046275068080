/* ==================================================
   Disqus Styles
   ================================================== */

/*Disqus Recent Comments*/
/*p.dsq-widget-meta a:last-child {
    display: none;
}*/

img.dsq-widget-avatar {
	width: 55px;
	height: 24px;
	border: 0px;
	margin: 0px;
	padding: 0px 3px 3px 0px;
	float: left;
	border-radius: 50%;
}

a.dsq-widget-user {
	font-weight: bold;
	padding-top: 12px;
	padding-bottom: 25px;
}

p.dsq-widget-meta {
	margin: 10px!important;
	color: #004370;
}

li.dsq-widget-item { font-style: italic; }

.section--home #block-disqus-disqus_recent_comments { margin-bottom: 40px; }


#ddddisqus_thread iframe:first-child { max-height: 1px !important; }
