/* ==================================================
   Authors' style
   ================================================== */
.authors .list { margin-top: 40px; }

.article--author {
  .article__text {
    @include font-size(15px);
    color: $color-text-2;
  }

  .image--rounded {
    float: left;
    margin-left: 20px;
    margin-right: 10px;
  }

  .list & {
    margin-bottom: 40px;
    min-width: 280px;

    .image--rounded {
      float: none;
      margin-left: auto;
      margin-right: auto;
    }

    .author__name {
      margin-top: 20px;
      @include font-size(14px);
      font-weight: 900;
      font-style: italic;
      color: #000;
      text-align: center;
    }
  }
}

.section--author {
  .section__title { @extend %page__title; }
  
  .article--author { margin-bottom: 40px; }

  .author__name {
    @include font-size(32px);
    font-weight: 900;
    color: #000;
  }
}

@include breakpoint(medium) {
  .authors .list { justify-content: center; }
  
  .list .article--author { min-width: 200px; }
}

@include breakpoint(small) {
  .article--author {
    .image--rounded,
    .typography { float: none; }

    .typography {
      margin-top: 20px;
      padding-left: 20px;
    }
  }

  .section--author {
    .article--author { margin-bottom: 20px; }
  
    .author__name { @include font-size(18px); }
  }
}