/* ==================================================
   Various Link Styles
   ================================================== */

%link,
.link {
	position: relative;
	box-sizing: border-box;
	z-index: 1;
	cursor: pointer;
}

.link--absolute {
	@extend %text-hidden;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 4;
}

%link--more,
.link--more {
	display: block;
	padding: 8px 10px;
	@include font-size(12px);
	font-style: italic;
	color: #fff;
	text-transform: uppercase;
	letter-spacing: 1px;
	background-color: $color-primary-1;
	line-height:1;

	> span {
		padding-bottom: 1px;
		border-bottom: 1px solid #fff;
	}

	&:link,
	&:hover,
	&:active,
	&:visited { color: #fff; }
}

.article__more {
	padding-left: 0;
	font-weight: 700;
	color: $color-primary-3;
	background-color: transparent;

	> span {
		padding-bottom: 1px;
		border-bottom: 1px solid $color-primary-3;
	}

	&:link,
	&:hover,
	&:active,
	&:visited { color: $color-primary-3; }

	a,
	a:link,
	a:hover,
	a:active,
	a:visited { color: $color-primary-3; }
}