/* ==================================================
   Page styles
   ================================================== */

.page__content {
  margin-bottom: 80px;

  h1, h2, h3, h4, h5, h6 { margin-bottom: 1em; }

  .inner { padding: 0 100px; }

  p {
    + h1, + h2, + h3, + h4, + h5, + h6 { margin-top: 100px; }
  }

  @include breakpoint(medium) {
    margin-bottom: 40px;
    
    .inner {
      padding-left: 10px;
      padding-right: 10px;
    }

    h1, h2, h3, h4, h5, h6 { line-height: 1.18; }

    p {
      + h1, + h2, + h3, + h4, + h5, + h6 { margin-top: 40px; }
    }
  }
}