/* ==================================================
   Glossary list & single items
   ================================================== */

.glossary-item {
  .article__title {
    @extend %section__title;
    @include font-size(25px);
    margin-bottom: 8px;
    margin-left: 5px;

    > span:after { content: none; }
  }
}

.section--glossary {
  .list--articles .glossary-item  {
    margin-bottom: 30px;
    padding-bottom: 15px;
    border-bottom: 1px solid $color-border-alt;

    .flex-row {
      align-items: flex-end;
      justify-content: space-between;
    }

    .article__text {
      flex-basis: 800px;
      padding-left: 20px;
      max-width: 85%;
    }

    .article__more { padding: 0; }
  }

  .list--abc {
    margin-bottom: 15px;
    padding-left: 30px;
    box-sizing: border-box;

    + .list--abc { margin-bottom: 30px; }

    li { flex-basis: 30px; }

    li a {
      @include font-size(18px);
      color: $color-text-5;
      font-weight: 400;
    }

    .active a { font-weight: 900; }
  }

  .list--keys {
    padding-left: 15px;
    box-sizing: border-box;
  }

  .section--key .section__title {
    font-weight: 900;
    padding-left: 15px;
  }

  .placeholder-div {
    position: relative;
    margin-bottom: 20px;
    padding: 10px;
    background-color: $color-input-bg;
    cursor: pointer;

    &:before {
      @extend %icon-arrow-tiny;
      content: '';
      position: absolute;
      right: 10px;
      top: 50%;
      display: block;
      margin-top: -2px;
    }
  }
}

@include breakpoint(small-medium) {
  .section--glossary .list--articles .glossary-item {
    padding-bottom: 20px;

    .flex-row { flex-wrap: wrap; }

    .article__title {
      margin-left: 0px;
      @include font-size(18px);
    }

    .article__text {
      flex-basis: 100%;
      padding-left: 0;
      max-width: 100%;
    }

    .article__more {
      flex-basis: 100%;

      span { float: right; }
    }
  }

  .section--glossary .mobile-only { display: block !important; }

  .section--glossary .list--abc li a {
    display: block;
    padding: 8px;
    @include font-size(20px);
    text-align: center;
  }

  .section--glossary {
    .glossary__index {
      display: none;

      &.open {
        display: block;
        width: 100%;
        margin-top: -20px;
        box-shadow: $box-shadow;
        border-right: 1px solid $color-border;
        border-left: 1px solid $color-border;
        border-bottom: 1px solid $color-border;
        background-color: #fff;
        z-index: 30;
      }
    }

    .list--abc,
    .list--keys { padding-left: 0; }

    .list--keys { margin-top: 15px; }
  }
}