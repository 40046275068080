/* ==================================================
   Burger menu Css Icon
   ================================================== */

.burger-icon {
  position: relative;
  display: none;
  margin: 0;
  padding: 0;
  width: 28px;
  height: 28px;
  background-color: transparent;
  font-size: 0;
  text-indent: -9999px;
  cursor: pointer;
  transition: background-color 0.3s;
  overflow: hidden;

  @include breakpoint(burger-menu) { display: block; }

  &:focus { outline: none; }

  span {
    display: block;
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    height: 3px;
    background-color: $color-primary-1;
    transition-delay: 0.3s;

    &:before,
    &:after {
      position: absolute;
      display: block;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: $color-primary-1;
      content: "";
      transition-duration: 0.2s, 0.2s;
      transition-delay: 0.2s, 0s;
    }

    &:before {
      top: -7px;
      transition-property: top, transform;
    }

    &:after {
      bottom: -7px;
      transition-property: bottom, transform;
    }
  }

  &.open span {
    background: none;
    transition-delay: 0s;

    &:before {
      top: 0;
      transform: rotate(45deg);
    }

    &:after {
      bottom: 0;
      transform: rotate(-45deg);
    }

    &:before,
    &:after { transition-delay: 0s, 0.2s; }
  }
}