/* ==================================================
   Article
   ================================================== */

%article__info {
  @include font-size(13px);
  font-weight: 300;
  font-style: italic;
  color: $color-text-6;
  margin-bottom:3px;

  strong {
    display: inline-block;
    margin-right: 4px;
    font-weight: 800;
  }

  a { color: $color-text-6; }

  .date {
    font-weight: 500;
    letter-spacing: 0.5px;
  }
}

%article__title {
  margin-bottom: 6px;
  @include font-size(25px);
  font-weight: 900;
  line-height: 1.15;
  color: $color-text-1;
}

%article-list-styles { border-bottom: 1px solid $color-text-4; }

.media_embed {
	position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0!important;

  iframe {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
	}
}

.article__gallery {
  width: 100%;
  margin: 30px auto 40px;
  max-width: 680px;

  .gallery__main { margin-bottom: 17px; }
}

.gallery__main {
  .slick-slide img {
    width: 100%;
    height: auto;
    cursor: grab;
  }
}

.gallery__thumb {
  .slick-slide {
    margin: 0 8px;
    text-align: center;
    max-width: 158px;
  }

  .slick-slide img {
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
  }

  .slick-arrow {
    top: 0;
    bottom: 0;
    width: 60px;
    height: 100%;
    margin-top: 0;
    box-shadow: none;
    background-image: none;
    opacity: 1;
    z-index: 100;
  }

  .slick-arrow:before {
    content: '';
    position: absolute;
    top: 50%;
    display: block;
    margin-top: -11px;
    background-color: transparent;
    z-index: 102;
  }

  .slick-prev {
    left: 0;
    background: linear-gradient(to right, rgba(255,255,255,1) 50%,rgba(255,255,255,0) 100%);

    &:before {
      @extend %icon-prev--pink;
      left: 0;
    }
  }

  .slick-next {
    right: 0;
    background: linear-gradient(to left, rgba(255,255,255,1) 50%,rgba(255,255,255,0) 100%);

    &:before {
      @extend %icon-next--pink;
      right: 0;
    }
  }
}

.article__text,
.article__body {
  line-height: 1.6;

  p {
    line-height: 1.6;
    @include font-size(17px);
    margin-bottom: 15px;
  }

  .list &,
  .section--home & {
    line-height: 1.3;

    p { line-height: 1.3; }
  }
}

.article__body,
.section--attachments {
  ul {
    @extend %reset-list;
    margin: 20px 0;

    li {
      display: block;
    }

    li:before {
      content: '';
      display: inline-block;
      width: 7px;
      height: 7px;
      margin-right: 18px;
      background-color: $color-primary-3;

    }
  }

  ol {
    @extend %reset-list;
    @extend %custom-counter !optional;
    margin: 20px 0;

    li {
      margin-top: 7px;
      padding-left: 20px;
    }

    li:before {
      left: 0;
      top: 3px;
      margin-right: 7px;
      @include font-size(20px);
      font-weight: 700;
      font-style: normal;
      line-height: 1;
    }
  }
}

.section--attachments {
  margin: 30px 0;
  padding: 30px 0;
  background-color: $color-primary-4;

  .section__title { padding: 0 15px; }

  ul { padding: 0 30px; }

  ul li {
    display: block;

    + li { margin-top: 10px; }
  }
}

.article--single {
  .relative-wrapper {
    position: relative;
    top: -90px;
    left: 10px;
    width: calc(100% - 10px);
    background-color: #fff;
  }

  .cell { vertical-align: middle; }

  .cell-placeholder {
    display: inline-block;
    width: 200px;
    height: 90px;
    vertical-align: middle;
  }

  .article-video {
    display: block;
    width: 100%;
    height: 480px;
    margin: 20px auto;
    padding: 0 40px;
    box-sizing: border-box;

    @include breakpoint(small-medium) {
      height: 320px;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .section--author { position: relative; }

  .author__image {
    position: absolute;
    top: -60px;
    left: 10px;
  }

  .title-wrapper {
    margin-bottom: 30px;
    padding-bottom: 25px;
    border-bottom: 1px solid $color-text-4;
  }

  .article__title {
    margin-bottom: 0;
    margin-top: 15px;
    padding-left: 20px;
    padding-right: 30px;
    @include font-size(32px);
    color: $color-text-1;
    font-weight: 900;
  }

  .article__info { @extend %article__info; }

  .article__body {
    padding-left: 20px;
    padding-right: 30px;

    blockquote p {
      @extend  %blockquote-text-style;

      @include breakpoint(small-medium) {
        @include font-size(28px);
        line-height: 1.2
      }
    }
  }

  .article__body,
  .article__body p {
    @include font-size(17px);
    color: $color-text-2;
  }

  .form--subscribe {
    display: flex;
    margin: 28px auto;
    padding: 20px;
    box-sizing: border-box;
    border-top: 1px solid $color-text-4;
    border-bottom: 1px solid $color-text-4;

    .section__title { margin-bottom: 2px; }

    .section__title > span {
      padding: 0;
      @include font-size(18px);
      font-weight: 900;

      &:before,
      &:after { content: none; }
    }

    .promo__text {
      @include font-size(15px);
      color: #7b7b7b;
    }

    p.promo__text {
      margin-bottom: 0;
      line-height: 1;
    }

    .typography,
    .form-wrapper {
      flex-basis: 50%;
      max-width: 50%;
    }

    .form-wrapper {
      .flex-row > .form__item:first-child { flex-basis: 100%; }

      [type="text"] { width: 100%; }
    }

    @include breakpoint(medium) {
      flex-wrap: wrap;
      padding-left: 0;
      padding-right: 0;

      .form-wrapper { padding: 0 10px; }

      .typography { margin-bottom: 15px; }

      .typography,
      .form-wrapper {
        flex-basis: 100%;
        max-width: 100%;
      }
    }

    @include breakpoint(small) {
      .form__item {
        max-width: 100%;

        + .form__item { margin-top: 15px; }
      }

      .form-wrapper .flex-row {
        flex-wrap: wrap;
        justify-content: center;
      }
    }
  }

  @include breakpoint(medium) {
    .article__body {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @include breakpoint(small-medium) {
    .relative-wrapper { top: -28px; }

    .cell-placeholder {
      width: 100px;
      height: 50px;
    }

    .author__image { top: -25px; }

    .image--rounded {
      width: 75px;
      height: 75px;
    }

    .article__title {
      margin-top: 7px;
      @include font-size(20px);
    }

    .title-wrapper {
      margin-bottom: 18px;
      padding-bottom: 18px;
    }

    .nav--social {
      margin-top: 15px;
      padding-left: 20px;
    }

    .article__info { padding-left: 20px; }

    .desktop-tablet { display: none !important; }

    .mobile-only { display: block !important; }
  }
}

.article--author {
  .article__more { @extend %body-more; }
}

.section--related { margin-top: 30px; }

.field-name-custom-single-article-mid-banner { margin-bottom: 30px; }

.tags-row {
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 40px auto;
  padding: 13px 20px;
  border-top: 1px solid $color-border;
  border-bottom: 1px solid $color-border;

  li {
    margin: 7px;
    @include font-size(14px);
    font-weight: 700;
    color: $color-text-5;

    a, a:link, a:hover, a:active, a:visited { color: $color-text-5; }

    a:hover { text-decoration: underline; }
  }

  .tags__title { font-weight: 900; }
}

.glossary--single {
  .article__title { margin-top: 0; }

  @include breakpoint(small-medium) {
    .title-wrapper {
      flex-wrap: wrap;

      > * { flex-basis: 100%; }
    }
  }
}

.article--book {
  .article__title { margin-bottom: 30px; }

  .book__image {
    float: left;
    width: 280px;
    margin-right: 20px;
    margin-bottom: 20px;

    @include breakpoint(small) {
      float: none;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.article--author{
  p{
    @include font-size(15px);
    line-height: 1.4;
  }
}


.gallery__main{
  li{
    position: relative;
  }
}

.gallery__title{
  position: absolute;
  bottom: 2px;
  right: 2px;
  background-color: rgba(255,255,255,.9);
  padding: 12px 24px 10px;
  color: #000;
  margin-left: 10%;
  font-weight: 600;
}

.article__google-news {
  a{
    color: #ed145b;
    font-weight: 600;
    &:hover{
      border-bottom: 1px solid;
    }
  }
  padding: 10px 0;
  margin: 24px 30px 20px 20px;
}
