/* ==================================================
   Our Helpers
   ================================================== */

.mobile-only,
.hidden { display: none !important; }

.text-left { text-align: left; }

.text-right { text-align: right; }

.text-center { text-align: center; }

.inline-block { display: inline-block; }

.bg-highlight { background-color: $color-highlight; }

.border-left-red {
	padding-left: 20px;
	border-left: 2px solid $color-primary-1;
}

.border-left-gray {
	padding-left: 20px;
	border-left: 2px solid $color-secondary-3;
}

.relative-wrapper { position: relative; }

.stop-scrolling { overflow: hidden; }

%center {
  margin-left: auto;
  margin-right: auto;
}

.center { @extend %center; }

%cf {
  display: block;

  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

.pad-l-10 { padding-left:10px; }

.pad-r-10 { padding-right:10px; }

.margin-b-30{ margin-bottom:30px;}

.italic { font-style:italic; }

.ad-block-spacing {
  padding-top:30px;
  padding-bottom: 30px;
}

.cf,
.clearfix { @extend %cf; }

%reset-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.reset-list { @extend %reset-list; }

%text-hidden {
	display: block;
	text-indent: -9999px;
	white-space: nowrap;
	outline: none;

	a { outline: none; }
}

.text-hidden { @extend %text-hidden; }

%text-continues {
	display: inline-block;

	&:after {
		content: '...';
		display: inline-block;
	}
}

@function calculate-rem($font-size) {
  $rem-size: $font-size/$base-font-size;
  @return #{$rem-size}rem;
}

@mixin font-size($font-size) {
  font-size: $font-size;
  font-size: calculate-rem($font-size);
}

@mixin sprite-background($image) {
  background-image: url('../images/'+$image+'.png?v=1');
  background-image: linear-gradient(transparent,transparent),url('../images/'+$image+'.svg?v=1');
  background-repeat: no-repeat;
}

@mixin breakpoint($point) {
  @if $point == large {
  	@media (max-width: 1300px) { @content ; }
  }

  @if $point == burger-menu {
  	@media (max-width: 1000px) { @content ; }
  }

	@if $point == medium {
  	@media (max-width: 900px) { @content ; }
  }

  @else if $point == small-medium {
    @media (max-width: 740px) { @content ; }
  }

  @else if $point == small {
    @media (max-width: 600px) { @content ; }
  }
}
