/* ==================================================
   Our Base Styles comment
   ================================================== */

html {
  margin: 0;
  padding: 0;

  font: {
  	family: $base-font-family;
  	size: $base-font-size;
  	weight: 400;
  }
}

body {
  margin: 0;
  padding: 0;
  font-size: 100%;
  font: inherit;
  line-height: 1.3;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section { display: block; }

table {
  border-collapse: collapse;
  border-spacing: 0;
}

p {
  margin:  0 0 0.3em;
  @include font-size(18px);
  color: $color-text-5;
  line-height: 1.2;

  @include breakpoint(medium) { @include font-size(17px); }
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .3em;
  @include font-size(30px);
  font-weight: 700;
  color: $color-primary-1;
}

a,
a:link,
a:hover,
a:active,
a:visited {
  color: $color-text-1;
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto!important;
}

blockquote {
  position: relative;
  display: inline-block;
  margin: 20px 50px 20px 0;
  text-align: left;

  p { display: inline; }
  
  &:after {
    @extend %icon-quote;
    content: '';
    display: inline-block;
    margin-bottom: -10px;
    margin-left: 25px;
  }

  @include breakpoint(small) {
    &:after {
      margin-bottom: -6px;
      transform: scale(0.6); 
    }
  }
}

%blockquote-text-style,
blockquote p,
blockquote {
  @include font-size(37px);
  color: $color-text-5;
  font-style: italic;
  font-weight: 500;
  line-height: 1.5;

  @include breakpoint(small-medium) {
    @include font-size(28px);
    line-height: 1.2
  }
}

::selection {
	background: $color-highlight;
	color: $color-text-5;
}

::-moz-selection {
	background: $color-highlight;
	color: $color-text-5;
}

*::-webkit-scrollbar-thumb { background: #ddd; }

*::-webkit-scrollbar { width: 10px; }

*::-webkit-scrollbar-thumb { background: #004370; }

*::-webkit-scrollbar-track { background: #ddd; }

#header ::-webkit-scrollbar-thumb { background: none; }

#header ::-webkit-scrollbar { width: 0px; }

#header ::-webkit-scrollbar-thumb { background: none; }

#header ::-webkit-scrollbar-track { background: none; }