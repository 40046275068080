/* ==================================================
   Video Styles
   ================================================== */

.video {
  .section--home.section--video & {
    margin-bottom: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid $color-text-4;

    .play-layer { margin-bottom: 8px; }
  }

  .play-layer {
    position: relative;

    a{
      &:before {
        @extend %icon-video;
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        margin-top: -30px;
        margin-left: -30px;
      }
    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}