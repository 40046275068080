/* ==================================================
   Poll Styles
   ================================================== */

#wajax_poll .title {
	margin-bottom: 16px;
	font-size: 20px;
	font-weight: 700;
}

#block-wajpoll-wajpoll_poll .poll .vote-form .choices { display: block; }

#block-wajpoll-wajpoll_poll label {
	display: inline-block;
	margin-bottom: 7px;
	padding-left: 10px;
	font-family: inherit;
	font-size: 14px;
	font-weight: 500;
	font-style: italic;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: 1.2px;
	text-align: left;
	color: #000;
}

.section--home #block-wajpoll-wajpoll_poll label {
	color: $color-text-5;
	font-family: $base-font-family;
}

#block-wajpoll-wajpoll_poll #edit-vote {
	float: left;
	margin-top: 16px;
}

#block-wajpoll-wajpoll_poll .form-checkboxes .form-item, .form-radios .form-item {
	margin-top: 0.6em;
	margin-bottom: 0.6em;
}

div#block-wajpoll-wajpoll_poll .form-radio {
	display: inline-block;
	height: 17px;
	width: 17px;
	margin-bottom: 4px;
	vertical-align: middle;
}

div#block-wajpoll-wajpoll_poll .form-radio:before {
	left: 5px;
	margin-top: -4px;
}

div#block-wajpoll-wajpoll_poll .form-radio:after {
  left: 0px;
  margin-top: -9px;
}