/* ==================================================
   The Footer
   ================================================== */

.site-footer {
	margin-top: 40px;
	padding-bottom: 78px;
	transition:padding-bottom 300ms linear;

	@include breakpoint(medium) { padding-bottom: 0; }

	.form--subscribe {
		margin-bottom: 30px;
		
		[type="email"],
		[type="text"] {
			width: 100%;
			max-width: 445px; 
		}

		.flex-row {
			> *:first-child {
				flex-basis: 100%;
				max-width: 100%;
			}
		}
	}

	.row--top {
		padding: 40px 0;
		background-color: $color-primary-4;

		.section__title { font-weight: 900; }

		.section__title span span { font-weight: 300; }

		.flex-row.content-wrapper {
			justify-content: space-between;

			> .flex-item { padding: 0 20px; }

			> .flex-item:first-child,
			> .flex-item:last-child {
				flex-basis: 300px;
				max-width: 300px;
			}

			> .flex-item.social {
				flex-basis: 600px;
				max-width: 600px;
			}

			> .flex-item:first-child > .row *:not(span) + *:not(span) { margin-top: 20px; }
		}

		.promo__text {
			@include font-size(14px);
			color: $color-text-2;
		}

		.address {
			@include font-size(14px);
			font-weight: 700;
			color: $color-text-5;

			span { display: block; }
		}

		.nav--secondary {
			li + li { margin-top: 8px; }

			li a {
				position: relative;
				display: block;
				padding: 6px 15px;
				@include font-size(14px);
				font-weight: 900;
				color: $color-text-5;

				&:before {
					content: '';
					display: block;
					position: absolute;
					left: 0;
					top: 50%;
					width: 5px;
					height: 8px;
					margin-top: -4px;
					background-color: $color-primary-3;
				}
			}
		}

		@include breakpoint(medium) {
			.flex-row.content-wrapper {
				flex-wrap: wrap; 
				
				> .flex-item:nth-child(n) {
					flex-basis: 100%;
					max-width: 100%;

					+ .flex-item { margin-top: 40px; }
				}

				.flex-item.social { order: 3; }

				.form--subscribe { display: none; }
			}
		}
	}

	.row--middle {
		background-color: $color-primary-2;
		text-align: center;
		position: relative;
		z-index: 100;

		* {
			@include font-size(12px);
			font-weight: 300;
			color: #fff; 
		}

		.content-wrapper { padding: 20px; }
		
		.flex-row { justify-content: center; }

		.nav--secondary {
			display: block;
			margin: 20px auto;
			text-align: center;

			li + li { margin-left: 15px; }
		}
	}

	.row--bottom {
		padding: 15px 0;
		box-sizing: border-box;
		background-color: $color-primary-3;

		a, p {
			@include font-size(12px);
			font-weight: 800;
			font-style: italic;
			color: #fff;
			line-height: 1.2;
			text-transform: uppercase;
		}

		.button { margin-left: 15px; }

		.button:hover { color: $color-primary-3; }

		p { margin-bottom: 0; }

		.flex-row {
			justify-content: center;
			align-content: center;
		}

		@include breakpoint(medium) { display: none; }
	}

	.credits__logo {
		@extend %icon-credits;
		display: block;
		margin-left: auto;
		margin-right: auto;
		max-width: 110px;
	}
	
	@include breakpoint(medium) {
		.copy__text { padding-top: 30px; }

		.credits__logo { transform: scale(.9); }
	}
}

.site-footer__logo {
	display: block;
	width: 210px;
	
	img {
		display: block;
		width: 100%;
	}
}

.go-to-top {
	position: absolute;
	top: -18px;
	right: 0;
	display: block;
	width: 50px;
	height: 50px;
	background-color: $color-text-5;
	z-index: 30;

	&:before {
		@extend %icon-arrow-up;
		content: '';
		position: absolute;
		left: 50%;
		top: 50%;
		display: block;
		margin-top: -8px;
		margin-left: -12px;
	}

	@include breakpoint(large) { right: 20px; }

	@include breakpoint(medium) {
		right: 50%;
		margin-right: -25px;
	}
}

.region-footer-stickyrow {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 99999;
}

.site-footer.no-padding{
	padding-bottom:0;

	.region-footer-stickyrow {
		display: none;
	}
}

.sub-slice{
    transition: transform 300ms linear;
    transform: translateY(110%);
}

.sub-visible{
	transform: translateY(0);
}

.hide-sub{
	right: 50px;
	top: -6px;
	display: block;
	position: absolute;
	height: 20px;
	font-weight: 800;
	color: #fff;
	font-style: italic;
	font-size: 12px;
	background-color: #ed145b;
	padding: 4px 30px 7px 15px;
	border-radius: 10px;
	cursor: pointer;

	&:after{
		content:"";
		position: absolute;
		width: 15px;
		height: 15px;
		right: 10px;
		top: 4px;
		background-position: -707px -159px;
		@include sprite-background(sprite);
	}
}