/* ==================================================
   Our Icons
	 ================================================== */

%icon-arrow-down {
	width: 8px;
	height: 6px;
	@include sprite-background(sprite);
	background-position: -832px -99px;
}

%icon-arrow-up {
	width: 25px;
	height: 16px;
	@include sprite-background(sprite);
	background-position: -498px -33px;
}

%icon-prev--pink {
	width: 15px;
	height: 23px;
	@include sprite-background(sprite);
	background-position: -772px -30px;
}

%icon-next--pink {
	width: 15px;
	height: 23px;
	@include sprite-background(sprite);
	background-position: -805px -30px;
}

%icon-search {
	width: 18px;
	height: 18px;
	@include sprite-background(sprite);
	background-position: -565px -32px;
}

%icon-social {
	width: 30px;
	height: 30px;
	@include sprite-background(sprite);
}

%icon-facebook { background-position: -30px -157px; }

%icon-twitter { background-position: -69px -157px; }

%icon-gplus { background-position: -109px -157px; }

%icon-linkedin { background-position: -149px -157px; }

%icon-youtube { background-position: -189px -157px; }

%icon-instagram { background-position: -189px -192px; }

%icon-facebook-tr { background-position: -471px -159px; }

%icon-twitter-tr { background-position: -505px -158px; }

%icon-linkedin-tr { background-position: -436px -159px; }

%icon-email-tr { background-position: -540px -159px; }

%icon-credits {
	width: 95px;
	height: 15px;
	@include sprite-background(sprite);
	background-position: -325px -164px;
}

%icon-video {
	width: 60px;
	height: 60px;
	@include sprite-background(sprite);
	background-position: -395px -49px;
}

%icon-close {
	width: 20px;
	height: 20px;
	@include sprite-background(sprite);
	background-position: -613px -31px;
}

%icon-quote {
	width: 55px;
	height: 44px;
	@include sprite-background(sprite);
	background-position: -235px -58px;
}

%icon-arrow-tiny {
	width: 8px;
	height: 5px;
	@include sprite-background(sprite);
	background-position: -778px -108px;
}

%icon-link-more {
	width: 5px;
	height: 5px;
	@include sprite-background(sprite);
	background-position: -748px -165px;
}

%icon-arrow-light {
	width: 23px;
	height: 20px;
	@include sprite-background(sprite);
	background-position: -719px -31px;
}
