/* ==================================================
   Breadcrumb
   ================================================== */

.breadcrumb {
  margin-top: 18px;
  text-align: center;

  .item-list {
    margin-bottom: 30px;
    border-bottom: 1px solid $color-border;
  }

  > ul { padding-left: 0; }

  ul li {
    display: inline-block;
    position: relative;
    padding: 0;
    margin: 0;
    z-index: 4;

    &:after {
      content: '/';
      margin-left: 5px;
      margin-right: 5px;
      display: inline-block;
      vertical-align: middle;
   }

    &.last {
      font-weight: 700;

      &:after { display: none; }
    }
  }

  li a {
    @extend %link;
    color: $color-text-1;
    text-decoration: none;
    @include font-size(12px);
  }
}

@include breakpoint(medium) {
  .breadcrumb-wrapper { display: none; }
}
