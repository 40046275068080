/* ==================================================
   Lists
   ================================================== */

.list {
	.article {
  	img {
      display: block;
      width: 100%;
      height: auto;
    }

    .typography {
      position: relative;
      margin-left: 10px;
      margin-top: -30px;
      padding: 20px 14px 15px 20px;
      background-color: #fff;
      border-bottom: 1px solid $color-text-4;
      width: calc(100% - 44px);
      z-index: 4;
    }
    
    .typography--blue {
      background: #004370;
      color: #fff;

      .article__title a,
      .article__category { color:#fff; }
      
      .article__info,
      .article__info a { color: #e4e7ea; }

      .featured-bullets {
        li { color: #fff; }
      }
    }
  
	  .article__title {
	    @include font-size(14px);
	    font-weight: 700;
	    color: $color-text-1;
	  }
	
	  .article__category {
	    position: relative; 
	    margin-bottom: 10px;
	    @include font-size(12px);
	    font-weight: 900;
	    color: $color-text-1;
	    letter-spacing: 1.8px;
	
	    &:before {
	      content: '';
	      position: absolute;
	      left: -15px;
	      bottom: 3px;
	      display: block;
	      width: 5px;
	      height: 15px;
	      background-color: $color-primary-3;
	    }
	  }
	
	  &.article--featured {
	    .article__title {
	      @include font-size(32px); 
	      font-weight: 900;
	      line-height: 1.1;
	    }
	  }
	
	  .article__info { @extend %article__info; }
	}
}

.section--first.list .article--featured .typography { border-bottom: none; }

.article--featured {
  .article__image { margin-bottom:15px; }
}

.list--fixed {
  max-height: 330px;
  overflow-y: auto;
}

.list--highlights {
  .article__title {@include font-size(25px);  }
}

.list__item {
  padding-top: 17px;
  padding-bottom: 9px;
  @include font-size(14px);
  font-weight: 700;
  background-color: #fff;
  line-height: 1.2;

  .emprty-div {
    position: relative;
    display: block;
    height: 80px;
    margin-top: -80px;
    background-color: rgba(#f9f9f9, .8);
    background: linear-gradient(to bottom, rgba(249,249,249,0) 0%,rgba(249,249,249,0.95) 80%,rgba(249,249,249,1) 100%);
    z-index: 4;
  }

  &.first {
    padding-top: 0;
    background-color: rgba(#f9f9f9, .8);
    border-bottom: 1px solid $color-border; 

    .typography {
      padding-top: 12px;
      position: relative;
      margin-top: -25px;
      z-index: 10;
    }
  }

  span {
    display: block;
    color: #666;
    line-height:1;
  }

  + .list__item {
    margin-top: 0;
    border-top: 1px solid $color-border; 
    margin-right:10px;
  }
}

.articles--row .flex-item-1-3 { max-width: 345px; }

.featured-bullets {
  position: relative;
  overflow: hidden;
  padding: 0;
  margin: 27px 0;

  &:after {
    content:"";
    position: absolute;
    top:0;
    left:50%;
    height: 100%;
    width:1px;
    background-color: #c6c6c6;
  }

  li {
    font-size: 14px;
    float: left;
    width: 50%;
    list-style: none;
    font-weight: 500;
    color: #393d3f;
    padding: 0 25px;
    box-sizing: border-box;
  }
}

.red-bullet {
  width: 6px;
  background: #ed145b;
  height: 5px;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: -2px;
}

.article__title + .article__info { margin-top:20px; }

/*pagination*/
.item-list{
  .pager{
    margin-top:30px;
    margin-bottom:30px;
    
    li{
      padding:0;
      margin:0;
    }
    
    a{
      font-size: 15px;
      height: 30px;
      width: 30px;
      display: inline-block;
      box-sizing: border-box;
      padding: 4px;
      font-weight: 400;
      font-style: italic;
    }
    
    .pager-current{
      color:#fff;
      background: #ed145b;
      font-size: 15px;
      height: 30px;
      width: 30px;
      display: inline-block;
      box-sizing: border-box;
      padding: 4px;
      font-weight: 400;
      font-style: italic;
    }
    
    .pager-first,
    .pager-last{
      display:none;
    }
    .pager-next a,
    .pager-previous a{
      font-size: 13px;
      width:100px;
      width: auto;
    }
    
    .pager-previous a:before{
      content:"";
      float:left;
      width: 20px;
      height: 15px;
      background-position: -779px -164px;
		  @include sprite-background(sprite);
    }
    
    .pager-next a:after{
      float:right;
      width: 20px;
      height: 15px;
      content:"";
      background-position: -802px -164px;
		  @include sprite-background(sprite);
    }
  }
}

@include breakpoint(medium) {
  .list .article__info { margin-bottom: 5px; }
  
  .list .article--featured .article__title { @include font-size(25px); }

  .articles--row {
    .flex-row { flex-wrap: wrap; }

    .flex-item-1-3 { margin-bottom: 20px; }

    .flex-item-1-3,
    .flex-item-2-3 {
      flex-basis: 100%;
      max-width: 100%;
    }
  }
}
  
@include breakpoint(small) {
  .featured-bullets li { @include font-size(15px); }
  
  .list__item {
    @include font-size(16px);
    line-height: 1.3;
  }
  
  .list {
    .article {
      .article__title { @include font-size(16px); }
    }
  }
  
  .list .article--featured .article__title { @include font-size(18px); }
}

.list--polls {
	justify-content: space-between;
	
	.poll {
		flex-basis: 48%;
		margin-bottom: 40px;
		max-width: 48%;
		
		@include breakpoint(small) {
			flex-basis: 100%;
			margin-bottom: 30px;
			max-width: 100%;
		}
	}
}
