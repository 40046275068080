/* ==================================================
   Our Variables
   ================================================== */

$base-font-size: 16px;
$base-font-family: 'Alegreya Sans', 'Open Sans', Helvetica, Arial, sans-serif;
$content-width: 1260px;

//Colors
$color-text-1:      #000;    //text
$color-text-2:      #2f2f2f; //gray dark text
$color-text-3:      #999;    //gray text
$color-text-4:      #175e8a; //navy light text
$color-text-5:      #002f4e; //navy darker text
$color-text-6:      #666;    //another gray text

$color-primary-1:   #004370; //blue
$color-primary-2:   #001a2b; //blue dark
$color-primary-3:   #ed145b; //cerise
$color-primary-4:   #f7f7f7; //almost white
$color-primary-5:   #d6dfea; //blue very very light

$color-secondary-1: #262626; //gray
$color-secondary-2: #9e1a1b; //red dark
$color-secondary-3: #fcf6ed; //beigz
$color-secondary-4: #424242; //gray dark
$color-secondary-5: #fae9e9; //pink
$color-secondary-6: #f3f3f3; //gray super light
$color-secondary-7: #393d3f; //gray text
$color-secondary-8: #979797; //

$color-border:      #e6e6e6;
$color-border-alt:   $color-text-4;

$color-placeholder: $color-text-5;
$color-input-bg:    #f5f5f5;
$color-error:       #f34235;
$color-success:     #234600;
$color-highlight:   $color-primary-3;

$color-facebook:    #3b5998; //social sharing buttons
$color-twitter:     #4099ff;
$color-whatsapp:    #25d366;
$color-messenger:   #0084ff;
$color-email:       #333;

//Additional fonts
$serif-font: 'Roboto Slab', serif;

//extras
$box-shadow: 0px 5px 15px 0 rgba(0, 0, 0, 0.15);
$box-shadow--full: 0px 2px 17px 2px rgba(37, 37, 37, 0.25);
