/* ==================================================
   Home Categories List
   ================================================== */

.section--category {
  margin-bottom: 0;
  padding-bottom: 40px;

  .article--featured .article__title { @extend %article__title; }

  .article--featured{
    .article__text,
    .article__info { margin-left: 10px; }
    > a {
      margin-left: 10px;
      display: inline-block;
    } 
  }
}

.video--featured {
  .video__title {
    display: inline-block;
    margin-bottom: 10px;
    margin-left:10px;
  }

  .article__text { margin-left:10px; }
}

.category__header {
  justify-content: space-between;
  margin-bottom: 25px;
  padding: 0 15px;
  box-sizing: border-box;

  &.no-padding { padding: 0; }

  @include breakpoint(small-medium) { padding: 0; }
}

.category__title {
  @extend %section__title;
  flex-basis: 100%;
  margin-bottom: 0;
  @include font-size(25px);
  font-weight: 300;
  color: $color-text-1;
  text-transform: uppercase;

  strong { font-weight: 900; }
}

.category__link {
  flex-basis: 120px;
  align-self: flex-end;
  @include font-size(12px);
  font-weight: 900;
  font-style: italic;
  color: $color-primary-3;
  text-align: right;
  text-transform: uppercase;

  span {
    display: inline-block;
    margin-bottom: 6px;
    padding-bottom: 1px;
    color: $color-primary-3;
    border-bottom: 1px solid $color-primary-3;
  }
}

.section--category {
  .list--articles {
    &.flex-row {
      justify-content: space-between;

      li {
        @extend %flex-item-1-2;
        max-width: 460px;
  
        &:nth-child(odd) {}
      }

      .article {
        @extend %article-list-styles;
        margin-bottom: 40px;
        padding-bottom: 40px;
      }
    }

    &.articles--row {
      li { margin-bottom: 40px; }

      a img { margin-bottom: 0; }

      .article { padding-left: 20px; }

      .article .inner {
        @extend %article-list-styles;
        position: relative;
        height: 100%;

        .article__info {
          position: absolute;
          bottom: 15px;
        }
      }

      .article__text { padding-bottom: 30px; }
    }
  }

  .article__text,
  .article__text p {
    @include font-size(15px);
    margin-bottom: 10px;
    color: $color-secondary-7;
  }
}

.section--articles {
  a img {
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
}

.section--category.section--articles {
  .article__title { @extend %article__title; }
}

@include breakpoint(medium) {
  .category__title { @include font-size(18px); }

  .category__header { margin-bottom: 15px; }

  .section--category .list--articles.flex-row li {
    flex-basis: 100%;
    max-width: 100%;
    margin-left: 20px;
    margin-right: 20px;
  }

  .section--category .list--articles.flex-row .article {
    margin-bottom: 20px;
    padding-bottom: 20px;
  }

  .section--category .list--articles.flex-row li {
    margin-left: 0;
    margin-right: 0;
  }

  .section--category.section--articles {
    max-width: 460px;
    margin-left: auto;
    margin-right: auto;
  }

  .section--category .list--articles.articles--row .article .inner { padding-bottom: 20px; }

  .section--category .list--articles.articles--row .article { padding-left: 0; }

  .section--category .list--articles.articles--row li { margin-bottom: 20px; }

  .section--category .article--featured .article__title, 
  .section--articles .article__title { @include font-size(18px); }
}