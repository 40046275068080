/* ==================================================
   Sticky Styles
   ================================================== */

.sticky-container {
	position: fixed;
	padding: 0px;
	margin: 0px;
	right: -130px;
	top: 230px;
	width: 210px;
	z-index: 1100;
}

.sticky li {
  list-style-type: none;
  background-color: #fff;
  color: #efefef;
  height: 43px;
  padding: 0px;
  margin: 0px 0px 1px 0px;
  transition:all 0.25s ease-in-out;
  cursor:pointer;
}

.sticky li:hover { margin-left: -115px; }

.sticky li img {
  float: left;
  margin: 5px 4px;
  margin-right: 5px;
}

.sticky li p {
	padding-top: 5px;
	margin: 0px;
	line-height: 16px;
	font-size: 11px;
}

.sticky li p a {
	text-decoration: none;
	color: #2C3539;
}

.sticky li p a:hover { text-decoration:underline; }