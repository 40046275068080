/* ==================================================
   Company
   ================================================== */

.section--category .company .article__title { @extend %article__title; }

.company {
  &.article--full {
    .article__title {
      margin-bottom: 15px;
      margin-left: 20px;
    }

    .article__more { @extend %body-more; }

    .flex-item-360 {
      flex-basis: 360px;
      max-width: 360px;
    }

    .company__tabs {
      position: relative;
      margin-left: 40px;
    }

    .typography {
      padding-bottom: 2px;
      border-bottom: 1px solid $color-border;
    }

    .tab--city {
      display: inline;

      &:first-child .tab__link { padding-right: 20px; }

      + .tab--city {
        .tab__link {
          padding-right: 20px;
          padding-left: 20px;
          border-left: 1px solid $color-border;
        }
      }
    }

    .tab__content {
      position: absolute;
      left: 0;
      right: 0;
      top: 40px;
      width: 100%;
      visibility: hidden;
      z-index: -1;

      &.active {
        visibility: visible;
        z-index: 2;
      }
    }

    .tab__link {
      position: relative;
      @include font-size(15px);
      font-weight: 700;
      color: #a3acb4;
      cursor: pointer;

      .inner {
        padding: 1px 10px;
        border-bottom: 3px solid transparent;
      }

      &.active {
        color: $color-text-5;

        .inner { border-color: $color-primary-3;  }
      }
    }

    address > * {
      display: block;
      margin-bottom: 7px;
      border-bottom: 1px solid $color-border;

      strong { display: block; }
    }
    
    @include breakpoint(small-medium) {
      .flex-row { flex-wrap: wrap; }

      .flex-item,
      .flex-item-360 {
        flex-basis: 100%;
        max-width: 100%;
      }

      .company__tabs { margin-left: 0; }

      .company__image { height: auto; }

      .nav--social { margin-bottom: 20px; }
    }
  }

  .article__text {
    box-sizing: border-box;
    padding-right: 15px;
  }

  address {
    > * {
      margin: 0;
      padding: 0 0 6px;
      @include font-size(15px);
      font-weight: 400;
      font-style: normal;
      color: $color-text-5;

      strong { font-weight: 700; }
    }
  }

  .branch + .branch {
    margin-top: 3px;
    padding-top: 10px;
    border-top: 1px solid $color-border; 
  }

  .section--companies & {
    .flex-item-100 { max-width: 620px; }
  }

  @include breakpoint(small-medium) {
    .section--companies & {
      .flex-row { flex-wrap: wrap; }

      .flex-item-100 { order: 2; }

      .flex-item-300 {
        order: 1;
        margin-left: 0;
        padding-left: 12px;
      }

      .article__text { display: none; }
    }
  }
}

.company__image {
  width: 358px;
  height: 230px;
  margin-bottom: 40px;
  box-shadow: $box-shadow;

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  @include breakpoint(small-medium) {
    width: 100%;
  }
}

.section--companies .list {
  .article {
    padding: 20px;
    border-bottom: 1px solid $color-border-alt;
  }
}

@include breakpoint(small-medium) {
  .section--companies .list .article {
    padding-left: 0;
    padding-right: 0;
  }
}