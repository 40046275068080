/* ==================================================
   The Header
   ================================================== */

.pre-header {
	background-color: #f7f7f7;

  .row--top {
		background-color: $color-primary-4;
		border-bottom: 2px solid $color-border;

		.banner--top {
			display: block;
			margin: 0 auto;
			text-align: center;
		}

		img {
			display: block;
			margin: 0 auto;
		}
	}
}

.site-header {
	position: relative;
	width: 100%;
	right: 0;
	left: 0;
	transition: all .3s ease .3s;
	z-index: 40;

	.flex-row {
		align-items: center;
    justify-content: center;
	}

	.site-navigation {
		justify-content: center;

		li a {
			position: relative;
			z-index: 1;
		}
	}

	.row--middle {
		background-color: #fff;
		padding: 35px 0 10px;

		.site-logo {
			flex-basis: 350px;
			max-width: 350px;
			transition: width .3s ease;

			img {
				display: block;
				width: 100%;
				height: auto;
			}
		}

		.site-search {
			display: none;
			margin-right: 15px;
			padding-right: 15px;
			border-right: 1px solid $color-border;
		}

		@include breakpoint(burger-menu) {
			padding: 22px 0 7px;

			.site-logo {
				flex-basis: 150px;
				max-width: 150px;
			}

			.site-search { display: block; }
		}
	}

	.row--bottom {
		color: $color-text-2;
		background-color: #fff;
		border-top: 1px solid $color-border;
		border-bottom: 1px solid $color-border;
		box-sizing: border-box;
		box-shadow: $box-shadow;

		.site-search {
			padding-left: 15px;
			border-left: 1px solid $color-border;
		}
	}
}

.site-search {
	padding-top: 8px;
	padding-bottom: 8px;
	cursor: pointer;

	&:before {
		@extend %icon-search;
		content: '';
		display: block;
	}
}

.nav--main {
	flex-basis: 100%;
	background-color: #fff;
	transition: all .3s ease;

	ul { @extend %reset-list; }

	ul li { box-sizing: border-box; }

	.site-navigation {
		@extend %flex-row;
		justify-content: center;
		margin: 0 auto;

		> li + li { margin-left: 20px; }

		@include breakpoint(large) {
			> li + li { margin-left: 7px; }
		}

		@include breakpoint(burger-menu) {
			justify-content: flex-start;

			> li + li { margin-left: 0; }
		 }

		li a {
			display: inline-block;
			padding: 18px 0;
			box-sizing: border-box;
			@include font-size(14px);
			font-weight: 900;
			color: $color-text-5;
			text-decoration: none;
		}

		.sub-wrapper {
			ul li + li { border-top: 1px solid $color-border; }

			> ul > li:last-child { border-bottom: 1px solid $color-border; }

			.sub-wrapper ul {
				li:first-child { border-top: 1px solid $color-border; }

				li.last { border-bottom: 0; }

				li a {
					padding-left: 30px;

					&:before {
						content: '';
						position: absolute;
						left: 13px;
						top: 50%;
						display: block;
						width: 6px;
						height: 6px;
						margin-top: -3px;
						background-color: $color-primary-3;
					}
				}
			}

			.sub-wrapper li a { font-weight: 400; }

			ul li a {
				line-height: 1.4;
				display: block;
			}
		}
	}
}

.nav--main .site-navigation.menu--desktop > li {
	border-bottom: 3px solid transparent;

	&.active { border-color: $color-primary-3; }
}

.nav--main .site-navigation > li {
	position: relative;
	padding: 0 10px;
	text-align: center;
	line-height: 1;
	transition: all .15s ease;

	> a { padding: 18px 0 16px; }

	/*
	//Just for testing
	&:first-child > .sub-wrapper {
		opacity: 1;
		visibility: visible;
	}
	*/

	&.expandable {
		> .arrow--down {
			@extend %icon-arrow-down;
			content: '';
			display: inline-block;
			margin-left: 8px;
			vertical-align: middle;
			transition: all .15s ease;
		}

		ul li.expanded { list-style: none; }
	}
}

.nav--main .site-navigation.menu--desktop > li:hover  {
	border-color: $color-primary-3;

	.arrow--down {
		transform: rotate(180deg);
		transition: all .1s ease;
	}
}

.nav--main .site-navigation > li > .sub-wrapper {
	position: absolute;
	top: 51px;
	left: -10px;
	display: flex;
	padding-bottom: 0;
	box-sizing: border-box;
	box-shadow: $box-shadow;
	background-color: #fff;
	text-align: left;
	opacity: 0;
	visibility: hidden;
	overflow: hidden;
	transition: 0.2s .2s;

	> ul {
		padding: 15px 20px;
		min-width: 170px;
	}

	li a {
		display: inline-block;
		padding: 5px 10px;
		@include font-size(14px);
		font-weight: 700;
	}
}

.nav--main .site-navigation.menu--desktop > li:hover > .sub-wrapper {
  opacity: 1;
	visibility: visible;
	transition-delay: .3s;
}

.nav--main .site-navigation.menu--desktop {
	position: relative;
	bottom: -1px;
}

@include breakpoint(large) {
	.nav--main .site-navigation > li.expandable > .arrow--down { margin-left: 9px; }
}

@include breakpoint(burger-menu) {
	.pre-header {
		padding-top: 65px;

		.ad-block-spacing { padding: 15px 0; }
	}

	.site-header {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;

		+ * { padding-top: 65px; }

		.row--middle {
			> .flex-row { justify-content: space-between; }

			.site-search { display: block; }
		}

		.row--bottom .site-search { display: none; }
	}

	.pre-header + .site-header + * { padding-top: 0; }

	.site-navigation > li + li { margin-left: 0; }

  #header .content-wrapper > div { margin-top: -15px; }

	.nav--main {
    position: relative;
    overflow-y: scroll;
    height: calc(100vh - 65px);
    z-index: 20;
		top: 0;
		left: 0;
		display: none;
		padding-top: 30px;
		padding-left: 20px;
		padding-right: 20px;

		&.menu-open { display: block; }

		.site-navigation {
			flex-wrap: wrap;
			text-align: left;
			align-items: flex-start;

			> * {
				flex-basis: 100%;
				max-width: 100%;
			}
		}
	}

	.nav--main .site-navigation > li {
		flex-basis: 100%;
		padding-left: 0;
		padding-right: 0;
		text-align: left;
		max-width: 100%;
	}

	.nav--main .site-navigation > li {
		border-bottom: 1px solid $color-text-5;

		> a {
			display: inline-block;
			padding: 9px;
			border-bottom: 0;
		}

		&.open { border-color: transparent; }
	}

	.nav--main .site-navigation.menu--mobile > li {
		>	.sub-wrapper {
			position: static;
			flex-wrap: wrap;
			width: 100%;
			box-shadow: none;
			max-height: 0;
		}

		&.open > .sub-wrapper {
			border-top: 1px solid $color-primary-3;
			background-color: $color-primary-4;
			visibility: visible;
			opacity: 1;
			max-height: none;

			> ul { padding: 0; }
		}

		.sub-wrapper li a {
			padding-left: 15px;
			padding-right: 15px;
		}

		.sub-wrapper .sub-wrapper {
			li a {
				padding-left: 45px;

				&:before { left: 25px; }
			}
		}
	}

	.nav--main .site-navigation.menu--mobile > li .sub-wrapper > * { flex-basis: 100%; }
}
