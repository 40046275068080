/* ==================================================
   Our Grid styles
   ================================================== */

%row {
  display: block;
  width: 100%;
  @extend %cf;
}

.row { @extend %row; }

.box,
.box-m {
	display: block;
	float: left;
	box-sizing: border-box;
}

.box-right,
.box-right-m {
	display: block;
	float: right;
	box-sizing: border-box;	
}

.box-1-2,
.box-1-2-m { width: 50%; }

.box-1-2 {
	@include breakpoint(medium) {
    width: 100%;
  }
}

.box-1-4 { width: 25%; }

.box-3-4 { width: 75%; }

%flex-row {
  display: flex;
  width: 100%;
  
  > * { box-sizing: border-box; }
}

.flex-row { @extend %flex-row; }

.flex-wrap { flex-wrap: wrap; }

.space-between { justify-content: space-between; }

%flex-item-300,
.flex-item-300 {
	flex-basis: 300px;
	max-width: 300px;

	.content-wrapper & {
		@include breakpoint(medium) {
			flex-basis: 100%;
			margin-left: auto;
			margin-right: auto;
		}
	}
}

%flex-item-100,
.flex-item-100 {
	flex-basis: 100%;
	max-width: 100%;
}

%flex-item-1-2,
.flex-item-1-2 {
	flex-basis: 50%;
	max-width: 50%;
}

.flex-item-1-3 {
	flex-basis: 33.33%; 
	max-width: 33.33%;
}

.flex-item-2-3 {
	flex-basis: 66.66%; 
	max-width: 66.66%;
}

.flex-item-1-4 { 
	flex-basis: 25%; 
	max-width: 25%;
}

%cell { display: table-cell; }

%cells {
	display: table;
	width: 100%;
	
	> * { @extend %cell }
}

.cells { @extend %cells; }

.cell { @extend %cell; }

.sidebar {
	box-sizing: border-box;
	float: right;
}

.inner { box-sizing: border-box; }
