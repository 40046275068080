/* ==================================================
   Shared Styles
   ================================================== */

.layout-container {
  width: 100%;
	overflow: hidden;
}

%content-wrapper,
.content-wrapper {
	position: relative;
  width: 100%;
	margin: 0 auto;
	box-sizing: border-box;
	max-width: $content-width;

	.inner { padding: 0; }

	.content--main {
		&.flex-item-100 {
			max-width: 940px;
			width: 100%;
		}
	}
	
	+ .content-wrapper { margin-top: 30px; }

	@include breakpoint(medium) { flex-wrap: wrap; }
	
	@include breakpoint(large) {
		padding: 0 20px;

		.content-wrapper { padding: 0; }
	}
}

%body-more {
	> span {
		display: block;

		.inner { position: relative; }

		.inner:before {
			@extend %icon-link-more;
			content: '';
			position: absolute;
			left: 100%;
			top: 50%;
			display: block;
			margin-top: -2px;
			margin-left: 5px;
			transition: transform .3 ease-in;
		}
	}
}

.page-vivlia,
.page-efimerida {
	#main { overflow: visible; }
}

.content--aside {
	margin-left: 20px;
	min-width: 300px;
}

.site-navigation {
	align-items: center;
	
	li a {
		@include font-size(14px);
		color: $color-text-2;
		text-decoration: none;
	}
}

.image-wrapper {
	position: relative;

	img {
		display: inline-block;
		max-width: 100%;
		height: auto;
	}
}

.badge {
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	@include font-size(10px);
	font-weight: 800;
	line-height: 27px;
	text-align: center;
	text-transform: uppercase;
	z-index: 2;

	&:after {
		position: absolute;
    content: '';
    right: -5px;
    top: 0;
    height: 27px;
    width: 10px;
    transform: skew(-20deg);
	}
}

.badge--new {
	width: 45px;
	color: $color-text-2;
	background-color: $color-primary-1;

	&:after { background-color: $color-primary-1; }
}

.badge--featured {
	width: 85px;
	color: $color-text-1;
	background-color: $color-highlight;

	&:after { background-color: $color-highlight; }
}

%fixed-layer {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
	bottom: 0;
	
	&.menu-layer {
		background-color: #fff;
		z-index: 30;
	}

	&.modal--search {
		background-color: rgba($color-primary-2, .96);
		z-index: 42;
	}

  &.active { display: block; }
}

.fixed-layer {
	@extend %fixed-layer;
	
	.modal-close {
		@extend %text-hidden;
		@extend %icon-close;
		position: absolute;
		top: -40px;
		right: 0;
		display: block;
	}

	.form-wrapper {
		position: relative;
		top: 25%;
		width: 80%;
    margin-left: auto;
		margin-right: auto;
		text-align: center;
		max-width: 960px;
	}
}

.dfp-tag-wrapper {
	text-align: center;
	
	iframe { max-width: 100%; }
	
	img {
    display: block;
    max-width: 100%;
    height: auto;
	}
}

.content--main .dfp-tag-wrapper {
	iframe { max-width: 100% !important; }
	
	img {
    display: block !important;
    max-width: 100% !important;
    height: auto !important;
	}
}

.fb_iframe_widget span {
	display: block;
	margin: 0 auto;
}

#toolbar-administration {
	a.edit-devel-toolbar { color: #565656; }

	.toolbar-menu {
		a,
		a:link,
		a:active,
		a:visited { color: #565656; }
	}
}

.nav { display: block; }

.image--rounded {
	display: block;
	width: 150px;
	height: 150px;
	box-sizing: border-box;
	border: 5px solid $color-border;
	border-radius: 50%;
	overflow: hidden;
}

.content--aside {
	.block + .block { margin-top: 40px; }

	.block:first-child .section__title { padding-top: 10px; }

	@include breakpoint(large) { margin-bottom: 30px; }
}

%custom-counter,
.custom-counter {
	li {
		position: relative;
		counter-increment: step-counter;
		padding-left: 30px;

		&:before {
			content: counter(step-counter);
			position: absolute;
			left: 8px;
			top: 17px;
			display: inline-block;
			margin-right: 5px;
			@include font-size(30px);
			color: $color-primary-3;
			font-weight: 500;
			font-style: italic;
		}
	}
}


.nav--social {
	li { display: inline-block; }

	li + li { margin-left: 10px; }

	li a {
		@extend %text-hidden;
		position: relative;
		width: 30px;
		height: 30px;

		&:before {
			@extend %icon-social;
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
		}
	}
	
	.facebook a:before { @extend %icon-facebook-tr; }

	.twitter a:before { @extend %icon-twitter-tr; }

	.linkedin a:before { @extend %icon-linkedin-tr; }


	.site-footer & {
		.facebook a:before { @extend %icon-facebook; }

		.twitter a:before { @extend %icon-twitter; }

		.google a:before { @extend %icon-gplus; }

		.linkedin a:before { @extend %icon-linkedin; }

		.youtube a:before { @extend %icon-youtube; }

		.instagram a:before { @extend %icon-instagram; }

	}
}

.block-dfp {
	.dfp-tag-wrapper .dfp-tag-wrapper {
		display: block;
    margin: 0 auto;
    text-align: center;
	}
}

#user-login {
	label { margin-bottom: 6px; }

	> div { @extend %content-wrapper; }

	.form-item .description {
		margin-top: 8px;
		font-style: italic;
	}

	.form-item, 
	.form-actions {
		width: 100%;
		margin-bottom: 20px;
		max-width: 460px;

		* { width: 100% }
	}
}

.no-content__text {
	margin-top: 20px;
	margin-bottom: 20px;
}

.profile { @extend %content-wrapper; }

.ad-block-spacing {
	padding: 30px;
	background-color: #f7f7f7;
}

.dsq-widget ul.dsq-widget-list {
  max-height: 290px;
  overflow-y: auto;
}

@include breakpoint(small-medium) {
	.sticky-container { display: none; }
	
	.content--main { padding-top: 30px; }

	.breadcrumb { display: none; }
}

.content-wrapper.max-600 {
	.article__title {
		margin-bottom: 30px;
		padding-left: 0;
		max-width: 600px;
	}
	
	.poll {
		margin-bottom: 60px;
		max-width: 600px;
		
		.form-submit { margin-top: 15px; }
	}
}
