/* ==================================================
   extra Styles (Load LAST)
   ================================================== */
  
.page-user .main { @extend %content-wrapper; }

.banner--insurance { position: relative; }

.banner__ins__link {
	position:absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 15;
}

.content-more {
	transition: max-height 300ms ease;
	max-height: 5000px;
	overflow: hidden; 
}

.content-hidden { max-height: 0; }

@media (max-width: 1300px) {
	.section--home.section--second .content-wrapper > .flex-row > .flex-item-1-3 {
		/*@fixes 7-5-2018*/
		margin-left: 0;
		overflow: hidden;
		padding: 0 5px;
		box-sizing: border-box;
		flex-basis: 25%;
	}
}

@media (max-width: 900px) {
	#block-wblocks-wblocks_latest_articles .list__item.first.list__item__photo img { display: none; }

	#block-wblocks-wblocks_latest_articles .list__item .emprty-div,
	#block-wblocks-wblocks_latest_articles .list__item .empty-div { background: none; }
	
	/*webform diafhmisi css*/
	#main .node-webform {
		padding: 0 10px;
		box-sizing: border-box;
	}

	/*header fix for webform pages*/
	#header .content-wrapper { padding: 0 20px; }
}

@media (max-width: 340px) {
	.section--home:not(.section--prints) .content-wrapper > .flex-row > .flex-item-300 { min-width: auto; }
	
	.section--today .flex-item-100 { flex-basis: 100%; }
}

@media (max-width: 1000px) {
	.nav--main { overflow-y: auto; }
	
	.site-header .nav--main {
		padding-left: 0;
		padding-right: 0;
	}
	
	.site-navigation li.open .arrow--down {
  	background-position: -832px -107px !important;
	}
	
	.article--single .form--subscribe {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		padding-left: 0;
		padding-right: 0;
	}
	
	#user-login > div .content--main.flex-item-100, 
	.profile .content--main.flex-item-100, 
	.page-user .main .content--main.flex-item-100, 
	.content-wrapper .content--main.flex-item-100 { max-width: 100%; }
	
	
	.article--single .form--subscribe .form-wrapper { padding: 0; }
	
	.article--single .form--subscribe .typography, .article--single .form--subscribe .form-wrapper {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
	}
	
	.article--single .form--subscribe .typography { margin-bottom: 15px; }
}

@media (max-width: 900px) {
	/*lists*/
	.section--home .content-wrapper>.flex-row { 
		position:relative;
		max-width:90%;	
	}
	
	.section--home.section--first .flex-item.center { margin-bottom: 30px; }

	.section--today .flex-item-300,
	.section--today,
	.today__text,
	.section--home:not(.section--prints) .content-wrapper>.flex-row>.flex-item-300,
	.section--home.section--video .content-wrapper .flex-row .flex-item,
	.section--home.section--third .flex-row>:last-child, .flex-item-300,
	.section--home.section--video .flex-item.left .left{
		max-width:100%;
		flex-basis: 100%; 
	}

	.section--home .list__item.first img,
	.list__item .emprty-div { display:none; }
	
	.list__item.first { background-color:transparent; }
	
	.section--home.section--first .banner--insurance { max-width:100%; }
	
	.category__link {
		position: absolute;
		top: 0;
		right: -2px;
		padding-left: 10px;
		background-color: #fff;
	}
	
	.section--home.section--video .category__link { background-color:#001a2b; } 
	
	.section--home.section--health .category__link { background-color:#d6dfea; }
}

@media (max-width:740px) {
	.front .content--main { padding-top: 0; }
}


@media (max-width: 600px) {
	.list .article .typography {
		box-sizing: border-box;
		width: 100%;
		margin-left: 0;
		margin-top: 0;
	}

	.section--home .list__item.first img, 
	.list__item .emprty-div { display:block; }
	
	.typography {
		top: auto; 
		bottom: auto; 
		max-width: 100%; 
	}
	
	.list .article.article--featured .article__title {
		font-size: 1.4rem;
		font-weight: 900;
		line-height: 1.3;
	}

	.featured-bullets li{
		padding:0;
		float:none;
		width:100%;
	}
	
	.featured-bullets li:first-child { padding-bottom:15px; }

	.featured-bullets:after { display: none; }
	
	.section--home .content-wrapper > .flex-row { max-width:100%; }
	
	.section--home.section--second .content-wrapper>.flex-row>.flex-item-1-3:nth-child(n) { max-width:100%; }
	
	#block-wblocks-wblocks_latest_articles {
		margin-top:
		20px;
	}
	
	.subscribe .form__item, 
	.subscribe .form-item, 
	.subscribe .form-actions { max-width:100%; }
	
	.article--single .form--subscribe .form-wrapper .flex-row {
		-webkit-box-pack: left;
    -ms-flex-pack: left;
    justify-content: left;
	}
}

@media (max-width: 450px) {	
	.article__body img {
		width: 100%;
		height: auto !important;
	}
}
