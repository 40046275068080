/* ==================================================
   Our Buttons
   ================================================== */

%button {
	display: inline-block;
	box-sizing: border-box;
	@include font-size(14px);
	font-weight: 800;
	text-decoration: none;
	text-align: center;
	color: inherit;
	letter-spacing: .04em;
	cursor: pointer;
	border: 1px solid transparent;
	border-radius: 0;
	box-shadow: none;
	appearance: none;
	transition: all ease-in .3s;

	&:hover { box-shadow: $box-shadow--full; }
}

.button,
.form-submit,
[type="submit"] { @extend %button; }

%button--action,
.button--action,
[type="submit"] {
	padding: 0 40px;
	@include font-size(14px);
	font-weight: 900;
	color: #fff;
	line-height: 38px;
	background-color: $color-primary-3;
	border-color: $color-primary-3;
	
	&:hover {  }
}

%button--ghost,
.button--ghost {
	padding: 15px;
	@include font-size(17px);
	font-weight: 700;
	line-height: 1;
	color: #fff;
	background-color: transparent;
	border: 2px solid #fff;

	&:hover {
		color: $color-primary-3;
		background-color: #fff;
	}
}

.button--fb {
  background-color: #3b5998;
  color: $color-text-2;
  text-align: center;
  padding: 10px 0;
  max-width: 302px;
  
  a {
    text-decoration: none;
    color: $color-text-2;
    text-transform: uppercase;
    @include font-size(12px);
    display: block;
    font-weight: 700;
    letter-spacing: 1px;
  }
  
  &:hover { opacity: 0.7; }
}
