/* ==================================================
   Today section
   ================================================== */

.section--today {
  * { color: #fff; }

  .flex-row {
    justify-content: space-between;
  }

  .flex-item-300 { 
    background-color: $color-primary-3;
    min-width: 300px;
  }

  .flex-item-100 {
    flex-basis: calc(100% - 300px);
    background-color: #003f6a;
    background-image: url('../images/san_simera.jpg');
    background-size: 100% auto;
    background-position-y: 78%;
    background-repeat: no-repeat; 
    max-width: calc(100% - 300px);
  }

  .typography { padding: 20px 30px; }
}

.today__strong {
  @include font-size(35px);
  font-weight: 900;
  font-style: italic;
  line-height: 1;
}

.today__light {
  @include font-size(32px);
  font-weight: 300;
  font-style: italic;
  line-height: 1;
}

.today__facts {
  li { position: relative; }

  &.slick-slider { height: 100%; }

  .slick-list,
  .slick-track { height: 100%; }
}

.today__text {
  box-sizing: border-box;
  padding-top: 20px;
  padding-left: 45px;
  padding-bottom: 20px;
  max-width: calc(100% - 300px);

  span, p { display: inline; }
}

.today__text,
.today__text p {
  @include font-size(15px);
  font-weight: 700;
}

.today__counter {
  position: absolute;
  right: 15px;
  bottom: 20px;
  display: block;
  @include font-size(30px);
  font-weight: 300;
  font-style: italic;
  z-index: 10;
}

@include breakpoint(medium) {
  .section--today {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
  }

  .today__facts { 
    max-width: 100%; 
   }

  .today__text {
    padding-left: 20px;
    padding-right: 20px;
    max-width: 100%; 
  }
  .section--today .flex-item-100{
    max-width: 100%;
  }
  
}
