/* ==================================================
   Map Styling
   ================================================== */

.map {
  position: relative;
  width: 100%;
  height: 230px;
  background-image: url('../images/map_desktop.png');
  background-size: cover;
  background-position: center;
  max-width: 940px;
}

.section--map {
  position: relative;
  margin-bottom: 40px;

  .typography {
    position: absolute;
    bottom: 40px;
    left: 10px;
    z-index: 10;

    .inner {
      padding: 20px;
      background-color: #fff;
      box-shadow: $box-shadow;
    }
  }

  address > * {
    margin: 0;
    padding: 7px 0;
    @include font-size(15px);
    font-weight: 400;
    font-style: normal;
    color: $color-text-5;

    + * { border-top: 1px solid $color-border; }
  }
}

@include breakpoint(small) {
  .map { background-image: url('../images/map_mobile.png'); }

  .section--map { margin-bottom: 80px; }

  .typography {
    top: 130px;
    bottom: auto;
    max-width: 290px; 
  }
}
