/* ==================================================
   Homepage
   ================================================== */

.section--home {
  margin-bottom: 40px;

  .list__item.first img {
    display: block;
    width: 100%;
    height: auto;
  }


  .article__info { @extend %article__info; }

  .block-wajpoll { margin: 7px auto 30px; }

  .poll .bar .foreground { background-color: $color-primary-3; }
}

.section--first .flex-item + .flex-item:after {
  width: calc(100% - 10px);
  content: "";
  background-color: #175e8a;
  height: 1px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.section--home.section--first {
  margin-top: 70px;

  .flex-item {
    &.left > * + * { margin-top: 20px; }

    &.center {
      flex-basis: 620px;
      max-width: 620px;
    }
  }

  .flex-item  + .flex-item {
    position: relative;
    margin-left: 20px;
  }

  .banner {
    display: block;
    width: 100%;
    height: 140px;
    padding: 13px;
    box-sizing: border-box;
    max-width: 300px;

    + .banner { margin-left: 5px; }
  }

  .banner__title {
    @include font-size(15px);
    font-weight: 700;
    font-style: italic;
    color: #fff;
  }
}

.list__item {
  a { display: inline-block; }
}

.section--home.section--second {
  margin-bottom: 0;

  /*.content-wrapper > .flex-row { margin-left: -10px; }*/

  .content-wrapper > .flex-row > .flex-item-1-3 {
    margin-bottom: 40px;
    margin-left: 20px;
    max-width: 300px;


    &:nth-child(1),
    &:nth-child(5) { margin-left: 0; }
  }

  .list--fixed {
    border-top: 1px solid $color-border;
    border-bottom: 1px solid $color-border;
    max-height: 230px;

    .list__item { padding-top: 9px; }
  }
}

.section--home.section--third {
  .flex-row {
    justify-content: space-between;

    > *:first-child {
      flex-basis: 100%;
      max-width: 940px;
    }

    > *:last-child { @extend %flex-item-300; }
  }

  .link--more {
    margin-top: 25px;
    padding-left: 180px;
  }

  .article__title {
    @include font-size(15px);
    font-weight: 700;
    color: $color-text-1;
  }

  .article__author {
    margin-bottom: 16px;
    @include font-size(14px);
    font-weight: 900;
    font-style: italic;
    color: $color-text-1;
  }

  .article__text {
    @include font-size(15px);
    color: $color-text-2;
  }
}

.section--home.section--category {
  padding-top: 40px;

  .article { @extend %article-list-styles; }

  .list {
    .article {
      padding: 33px 10px;
      margin-left: 30px;
    }

    .article {
      img {
        display: block;
        width: 100%;
        height: auto;
        margin-bottom: 20px;
      }
    }

    .article__title { margin-bottom: 0; }

    .article__info { margin-bottom: .25em; }
  }

  .list--alt {
    > li {
      //float: left;
      //width: 50%;
    }

    .dfp-tag-wrapper {
      margin: 15px 0;
      text-align: right;
    }

    .flex-ad-block { float: right; }

    @include breakpoint(small) {
      > li {
        float: none;
        width: 100%;
      }

      .dfp-tag-wrapper { text-align: center; }
    }
  }
}

.section--home.section--health {
  background-color: $color-primary-5;

  .category__title > span:after { background-color: #fff; }
}

.section--home.section--video {
  background-color: $color-primary-2;

  *, .article__text { color: #fff; }

  .flex-row { justify-content: space-between; }

  .flex-item.left {
    flex-basis: 100%;
    max-width: 940px;

    .left {
      flex-basis: 620px;
      max-width: 620px;
    }
  }

  .right .section__title { margin-top: 8px; }

  .category__title,
  .category__link { color: #fff; }

  .category__link span { border-color: #fff; }

  .video {
    margin-bottom: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid $color-text-4;

    .play-layer {
      margin-bottom: 8px;

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }

  .video__title {
    @include font-size(14px);
    font-weight: 700;
  }

  .video--featured {
    .video__title {
      @include font-size(25px);
      font-weight: 900;
    }

    .play-layer { margin-bottom: 12px; }
  }
}

.home__frontpages .iframe-wrapper { text-align: center; }

.section--highlights {
  padding: 0 20px;
  box-sizing: border-box;
  max-width: 1900px;
  margin-left: auto;
  margin-right: auto;

  .slick-track {
    display: flex;
    justify-content: center;

    li + li { margin-left: 20px; }
  }

  .slick-slide { float: none; }

  .article {
    position: relative;
    max-width: 450px;

    .image-wrapper {
      margin-bottom: 18px;
      max-width: 450px;
    }
  }

  .article__text,
  .article__text p {
    @include font-size(15px);
    margin-bottom: 10px;
    color: $color-secondary-7;
  }
}

.bloggers-home-block {
  .article__author {
    color: $color-text-1;
    font-size: 14px;
  }

  .box-1-4 {
    margin-right: 15px;
    width: calc(25% - 15px);
  }

  .image-wrapper {
    img {
      border-radius: 50%;
      border: 1px solid $color-primary-1;
    }
  }

}

@include breakpoint(medium) {
  .section--home.section--first { margin-top: 20px; }

  .section--home.section--third .link--more {
    margin-top: 10px;
    padding-left: 20px;
  }

  .section--home.section--category .list .article {
    margin-left: auto;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .section--home {
    .content-wrapper > .flex-row {
      flex-wrap: wrap;
      margin-left: auto;
      margin-right: auto;
      max-width: 600px;
    }

    &.section--first .flex-item + .flex-item {
      margin-left: auto;
      margin-top: 20px;
    }

    &.section--first .flex-item {
      order: 3;
    }

    &.section--first .flex-item.left {
      order: 2;
    }

    &.section--first .flex-item.center {
      order: 1;
    }

    &:not(.section--prints) {
      .content-wrapper > .flex-row > .flex-item,
      .content-wrapper > .flex-row > .flex-item-1-3,
      .content-wrapper > .flex-row > .flex-item-1-2 {
        flex-basis: 100%;
        max-width: 100%;
      }

      .content-wrapper > .flex-row > .flex-item-300 {
        margin-left: auto;
        margin-right: auto;
        max-width: 300px;
      }
    }

    &.section--prints .flex-item { margin-bottom: 40px; }

    &.section--second .content-wrapper > .flex-row { margin-left: auto; }

    &.section--second .content-wrapper {
      > .flex-row > .flex-item-1-3:nth-child(n) {
        flex-basis: 49%;
        margin-left: .5%;
        margin-right: .5%;
        max-width: 49%;
      }
    }

    &.section--third .flex-row > .block { margin-top: 40px; }

    &.section--video {
      .content-wrapper .flex-row { flex-wrap: wrap; }

      .content-wrapper .flex-row .flex-item {
        margin-left: auto;
        margin-right: auto;
        max-width: 300px;
      }
    }
  }

  .section--highlights .article { margin: 20px auto; }
}

.section--prints .content-wrapper { max-width: initial; }

@include breakpoint(small) {
  .section--home.section--category {
    .flex-item-1-2 {
      flex-basis: 100%;
      max-width: 100%;
    }
  }

  .section--home {
    &.section--second .content-wrapper {
      > .flex-row > .flex-item-1-3:nth-child(n) {
        flex-basis: 100%;
        margin-left: auto;
        margin-right: auto;
        max-width: 300px;
      }
    }

    &.section--third {
      .flex-row > *:last-child {
        flex-basis: 100%;
        max-width: 100%;
      }
    }
  }
}

.banner--insurance {
  position: relative;

  &:after {
    @extend %icon-arrow-light;
    content: '';
    position: absolute;
    right: 20px;
    bottom: 20px;
    display: block;
    z-index: 1;
  }
}

.cat-flex{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &__child{
    flex-basis: 50%;
    display: flex;
    flex-direction: column;

    > li{
      flex:1;
      display: flex;
    }
  }
}

.cat-flex__child-no-banner{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  li{
    flex-basis: 50%;
  }
}

ul.cat-flex__child{
  flex-basis: calc(50% - 20px);
}

.cat-flex__banners{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cat-banner-top{
  margin-bottom:16px;
}



.category__link-wrap{
  text-align: center;
  margin-top: 35px;

  .category__link{
    font-size:15px;
  }
}

@include breakpoint(medium) {

  ul.cat-flex__child,
  .cat-flex__child-no-banner li{
    flex-basis: 100%;

    .article{
      width:100%;
    }
  }
  .cat-flex__banners {
    flex-basis: 100%;
    flex-direction: row;
    margin-top: 20px;
  }
}

@include breakpoint(small-medium) {
  .cat-flex__banners {
    flex-direction:column;
    align-items: center;
  }
}

