/* ==================================================
   Form styling
	 ================================================== */

.section--form {
	background-color: $color-secondary-6;

	.messages__wrapper {
		[role="contentinfo"] { display: none; }
	}
	
	.form__item,
	.form-item {
		position: relative;
		margin-bottom: 10px;
		padding-top: 25px;
		box-sizing: border-box;
	}

	.form__title {
		@extend %title;
		margin-bottom: 20px;
		@include font-size(30px);
		color: $color-text-4;
	}

	textarea { min-height: 175px; }

	@include breakpoint(medium) {
		.form__title { @include font-size(15px); }

		.form__item,
		.form-item { margin-bottom: 15px; }
	}
}

.form__content {
	padding-top: 50px;
	padding-bottom: 30px;

	.inner {
		padding: 0 100px; 

		@include breakpoint(medium) {
			padding-left: 10px;
			padding-right: 10px;
		}
	}

	@include breakpoint(medium) {
		padding-top: 35px;
	}
}

%form__wrapper,
.form__wrapper {
	width: 100%;
	margin: 50px auto 0;
	justify-content: space-between;
	flex-wrap: wrap;
	text-align: center;
	
	.messages {
		max-width: 460px;
		text-align: center;
		margin: 0 auto;
		padding: 0;
	}

	@include breakpoint(medium) { margin-top: 30px; }
}

.form--inline {
	.contact-form,
	.webform-submission-form {
		@extend %flex-row;
		@extend %form__wrapper;

		.captcha {
			display: block;
			width: 100%;
			margin-bottom: 20px;
		}

		.form-item-message,
		.field--name-field-message,
		.form-actions {
			width: 100%;
			flex-basis: 100%;

			> * { max-width: 100%; }
		}
	}
}

.form--contact { text-align: center; }

.form--inline,
.form--inline-m {
	.form__item,
	.form-item {
		flex-basis: 49%;
		vertical-align: bottom;

		&.wide {
			width: 100%;
			flex-basis: 100%;

			> * { max-width: 100%; }
		}
		
		.form-label {
			margin-bottom: 5px;
			text-align: left;
 		}
	}
}

.form--inline {
	@include breakpoint(medium) {
		.form__item,
		.form-item { flex-basis: 100%; }
	}
}

.form--main {
  [type="tel"],
  [type="text"],
	[type="email"],
	[type="number"],
	[type="password"],
	select,
	textarea {
		width: 100%;
		border: 1px solid $color-border;
		
		&:focus { border-color: $color-highlight; }
	}

	[type="submit"] {
		@extend %button--action;
		@include font-size(16px);
		float: right;
		line-height: 38px;

		@include breakpoint(medium) {
			float: none;
			width: 100%;
		}
	}
	
	.form__item,
	.form-item {
		&.focus,
		&.filled {
			label {
				@include font-size(9px);
				top: 5px;
			}
		}
	}
	
	.form__checkboxes,
	.form__radios {
		.form__item,
		.form-item {
			display: inline-block;
			width: auto;
			
			+ .form__item,
			+ .form-item { margin-left: 18px; }
		}
		
		.option {
	    margin-left: 4px;
			@include font-size(12px);
			font-weight: 700;
		}
	}
}

.form__label {
	margin-bottom: 15px;
	@include font-size(15px);
	font-weight: 700;
	text-align: center;	
}

.form__item,
.form-item,
.form-actions { box-sizing: border-box; }

div.status, .ok { color: $color-success !important; }

.webform-confirmation__message {
	@include font-size(16px);
	color: $color-success;
	text-align: center; 
}

.message {
	position: absolute;
	left: 0;
	top: 0;
	@include font-size(14px);
	font-weight: 700;
	text-align: left;

	&.error {
		color: $color-error;

		+ * { border-bottom: 5px solid $color-error; }
	}

	&.status { color: $color-success; }

	&.warning { color: $color-primary-3; }
}

.form-email.error { border-bottom: 5px solid $color-error; }

.form-item--error-message {
	position: absolute;
	left: 0;
	top: 4px;
	@include font-size(14px);
	font-weight: 700;
	text-align: left;
	color: $color-error;
}

.form-item,
.form-actions { margin: 0; }

.search-page-form {
	.form-wrapper {
		@extend %flex-row;
		margin-bottom: 30px;

		.form-item {
			flex-basis: 100%;
			margin-left: 15px;
		}

		[type="text"] {
			width: 100%;
			background-color: #f5f5f5;
			border: solid 1px #f2f2f2;
			box-shadow: none;
		}

		@include breakpoint(small) {
			flex-wrap: wrap;
			justify-content: center;

			[type="submit"] { margin-top: 15px; }
		}
	}
}

.form--search {
	.flex-row {
		justify-content: center;

		> *:first-child {
			flex-basis: 100%;
			max-width: 800px;
		}
	}

	[type="text"] { width: 100%; }

	@include breakpoint(small) {
		.flex-row { 
			flex-wrap: wrap;

			> * + * { margin-top: 12px; }
		}
	}
}

.node-type-webform .content--main .webform-client-form {
	padding: 0 30px;

	> * {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.form-item {
		flex-basis: 48%;
		margin-bottom: 2%;
		max-width: 48%;

		label { margin-bottom: 7px; }

		fieldset { margin-bottom: 0; }
		
		[type="tel"], 
		[type="text"], 
		[type="email"], 
		[type="number"], 
		[type="password"], 
		[type="address"], 
		select,
		textarea {
			width: 100%;
			border: 0;
			box-shadow: none;
			background-color: $color-input-bg;
			max-width: 100%;

			&:focus {
				box-shadow: $box-shadow--full;
				background-color: #fff;
			}
		}
	}

	.webform-component-textarea {
		flex-basis: 100%;
		max-width: 100%;
	}

	.form-actions {
		width: 100%;
		margin-top: 35px;
		margin-bottom: 40px;
	}

	@include breakpoint(large) { padding: 0; }

	@include breakpoint(small) {
		.form-item {
			flex-basis: 100%;
			max-width: 100%;
		}

		.form-actions { margin-top: 30px; }
	}
}

@include breakpoint(medium) {
	.node-type-webform .content--main {
		.content-wrapper,
		.section--map .content-wrapper {
			padding-left: 0;
			padding-right: 0;
		}
	
		.field-name-custom-contact-us + .content-wrapper {
			padding-left: 20px;
			padding-right: 20px;
		}
	}
}