/* ==================================================
   Our Titles
   ================================================== */

%title {
	font-weight: 900;
	line-height: 1.2;
}

%page__title,
.page__title {
	@include font-size(25px);
	color: $color-text-1;

	@include breakpoint(small) { 
		@include font-size(18px);
	}
}

%general__title {
	@extend %title;
	display: block;
	margin-bottom: 18px;
	@include font-size(18px);
	font-weight: 300;
	color: $color-text-4;
	letter-spacing: 1px;

	strong,
	span span { font-weight: 900; }
}

%section__title,
.section__title {
	@extend %general__title;
	overflow-x: hidden;

	> span {
		position: relative;
		display: inline-block;
		padding: 0 10px 0 15px;

		&:before {
			content: '';
			position: absolute;
			left: 0;
			top: 50%;
			display: block;
			width: 5px;
			height: 15px;
			margin-top: -9px;
			background-color: $color-primary-3;
		}

		&:after {
			content: '';
			position: absolute;
			left: 100%;
			width: 2000%;
			bottom: 6px;
			display: block;
			height: 1px;
			background-color: $color-border;
		}
	}
}

.print__title {
	@extend %general__title;
	text-align: center;
}