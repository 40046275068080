/* ==================================================
   Slick Slider theme
   ================================================== */

.slick-slider { box-sizing: border-box; }

.slick-dots {
  @extend %reset-list;
  position: absolute;
  bottom: 80px;
  display: block;
  width: 100%;
  text-align: center;
  z-index: 8;

  > li {
    display: inline-block;
    vertical-align: middle;

    + li { margin-left: 7px; }

    button {
      @extend %button;
      @extend %text-hidden;
      display: block;
      width: 6px;
      height: 6px;
      padding: 0;
      border-width: 0;
      border-radius: 50%;
      background-color: #fff;
    }

    &.slick-active button {
      width: 22px;
      border-radius: 3px;
    }
  }

  @include breakpoint(medium) { bottom: 10px; }
}

.slick-arrow {
	@extend %text-hidden;
  position: absolute;
  top: 50%;
  width: 50px;
  height: 50px;
  margin-top: -25px;
  box-shadow: $box-shadow;
  @include sprite-background(sprite);
  background-color: transparent;
  border: 0;
  opacity: 0.92;
  cursor: pointer;
  transition: all .3s;
  z-index: 4;
  
  &:hover { opacity: 1; }
}

.slick-next {
  right: 20%;
  background-position: -644px -146px;
}

.slick-prev {
  left: 20%;
  background-position: -583px -146px;
}
