/* ==================================================
   Prints
   ================================================== */

.section--prints {
  padding: 0 10px;
  text-align: center;

  .flex-row {
    justify-content: space-between;

    @include breakpoint(large) { justify-content: space-around; }
  }

  &.section--home {
    margin: 40px auto;
    
    .flex-row { justify-content: space-around; }
  }

  .section__title {
    @extend %page__title;
    text-align: left;
  }

  .print .button--action {
    background-color: $color-text-5;
    border-color: $color-text-5;
  }

  .print__subtitle {
    color: $color-text-1;
    font-weight: 900;
    font-style: italic;
  }

  .print__subtitle,
  .print__date {
    @include font-size(14px);
    font-style: italic;
  }
}

.print {
  margin-bottom: 40px;

  .button--action {
    margin-top: 15px;
    color: #fff;
  }
}

.books{
  .print__subtitle{
    max-width: 190px;
    display: block;
    text-align: center;
    margin: 0 auto;
    box-sizing: border-box;
  }
}

.print__cover {
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  padding: 8px;
  box-sizing: border-box;
  box-shadow: $box-shadow;
  background-color: #fff;

  .section--home & { max-width: 219px; }

  img { display: block; }
}