/* ==================================================
   Benner section
   ================================================== */

.section--banner {
  background-color: $color-primary-4;
  
  .banner--top {
    display: block;
    margin: 0 auto;
    text-align: center;
  }
  
  a {
    display: block;
    padding: 30px 0;
  }

  img {
    display: block;
    margin: 0 auto;
    width: 100%;
    height: auto;
  }
}