
/**
 * @file
 * Basic styling for common markup.
 */

/**
 * HTML elements.
 */
 fieldset {
  margin-bottom: 1em;
  padding: 0.5em;
}
form {
  margin: 0;
  padding: 0;
}
hr {
  border: 1px solid gray;
  height: 1px;
}
img {
  border: 0;
}
table {
  border-collapse: collapse;
}
th {
  border-bottom: 3px solid #ccc;
  padding-right: 1em; /* LTR */
  text-align: left; /* LTR */
}
tbody {
  border-top: 1px solid #ccc;
}
tr.even,
tr.odd {
  background-color: #eee;
  border-bottom: 1px solid #ccc;
  padding: 0.1em 0.6em;
}

/**
 * Markup generated by theme_tablesort_indicator().
 */
th.active img {
  display: inline;
}
td.active {
  background-color: #ddd;
}

/**
 * Markup generated by theme_item_list().
 */
.item-list .title {
  font-weight: bold;
}
.item-list ul {
  margin: 0 0 0.75em 0;
  padding: 0;
}
.item-list ul li {
  margin: 0 0 0.25em 1.5em; /* LTR */
  padding: 0;
}

/**
 * Markup generated by Form API.
 */
.form-item,
.form-actions {
  margin-top: 1em;
  margin-bottom: 1em;
}
tr.odd .form-item,
tr.even .form-item {
  margin-top: 0;
  margin-bottom: 0;
  white-space: nowrap;
}
.form-item .description {
  font-size: 0.85em;
}
label {
  display: block;
  font-weight: bold;
}
label.option {
  display: inline;
  font-weight: normal;
}
.form-checkboxes .form-item,
.form-radios .form-item {
  margin-top: 0.4em;
  margin-bottom: 0.4em;
}
.form-type-radio .description,
.form-type-checkbox .description {
  margin-left: 2.4em;
}
input.form-checkbox,
input.form-radio {
  vertical-align: middle;
}
.marker,
.form-required {
  color: #f00;
}
.form-item input.error,
.form-item textarea.error,
.form-item select.error {
  border: 2px solid red;
}

/**
 * Inline items.
 */
.container-inline .form-actions,
.container-inline.form-actions {
  margin-top: 0;
  margin-bottom: 0;
}

/**
 * Markup generated by theme_more_link().
 */
.more-link {
  text-align: right; /* LTR */
}

/**
 * Markup generated by theme_more_help_link().
 */
.more-help-link {
  text-align: right; /* LTR */
}
.more-help-link a {
  background: url(../../misc/help.png) 0 50% no-repeat; /* LTR */
  padding: 1px 0 1px 20px; /* LTR */
}

/**
 * Markup generated by theme_pager().
 */
.item-list .pager {
  clear: both;
  text-align: center;
}
.item-list .pager li {
  background-image: none;
  display: inline;
  list-style-type: none;
  padding: 0.5em;
}
.pager-current {
  font-weight: bold;
}

/**
 * Autocomplete.
 *
 * @see autocomplete.js
 */
/* Suggestion list */
#autocomplete li.selected {
  background: #0072b9;
  color: #fff;
}

/**
 * Collapsible fieldsets.
 *
 * @see collapse.js
 */
html.js fieldset.collapsible .fieldset-legend {
  background: url(../../misc/menu-expanded.png) 5px 65% no-repeat; /* LTR */
  padding-left: 15px; /* LTR */
}
html.js fieldset.collapsed .fieldset-legend {
  background-image: url(../../misc/menu-collapsed.png); /* LTR */
  background-position: 5px 50%; /* LTR */
}
.fieldset-legend span.summary {
  color: #999;
  font-size: 0.9em;
  margin-left: 0.5em;
}

/**
 * TableDrag behavior.
 *
 * @see tabledrag.js
 */
tr.drag {
  background-color: #fffff0;
}
tr.drag-previous {
  background-color: #ffd;
}
.tabledrag-toggle-weight {
  font-size: 0.9em;
}
body div.tabledrag-changed-warning {
  margin-bottom: 0.5em;
}

/**
 * TableSelect behavior.
 *
 * @see tableselect.js
*/
tr.selected td {
  background: #ffc;
}
td.checkbox,
th.checkbox {
  text-align: center;
}

/**
 * Progress bar.
 *
 * @see progress.js
 */
.progress {
  font-weight: bold;
}
.progress .bar {
  background: #ccc;
  border-color: #666;
  margin: 0 0.2em;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
.progress .filled {
  background: #0072b9 url(../../misc/progress.gif);
}
