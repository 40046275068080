/* ==================================================
   Inputs
	 ================================================== */

[type="tel"],
[type="text"],
[type="email"],
[type="number"],
[type="password"],
[type="address"],
select {
	padding: 0 10px;
	box-sizing: border-box;
	@include font-size(14px);
	font-weight: 600;
	border: 1px solid #fff;
	line-height: 38px;
	appearance: none;
	outline: none;
	box-shadow: $box-shadow;
}

textarea {
	@include font-size(14px);
	padding: 10px;
	box-sizing: border-box;
	outline: none;
	box-shadow: $box-shadow;
}

label { cursor: pointer; }

input[type='radio'] { position: relative; }

input[type='radio']:before,
input[type='radio']:after {
	content: '';
	position: absolute;
  top: 50%;
	display: block;
	border-radius: 50%;
}

input[type='radio']:before {
	left: 2px;
  width: 8px;
  height: 8px;
  margin-top: -6px;
  background-color: #fff;
  z-index: 5;
}

input[type='radio']:after {
  left: -3px;
  width: 18px;
  height: 18px;
  margin-top: -11px;
  background-color: #dadada;
  z-index: 3;
}

input[type='radio']:checked:before { background-color: #ed145b; }

%placeholder-style {
	color: $color-placeholder;
	font-weight: 400;
	font-style: italic;
}

:-moz-placeholder {
	color: $color-placeholder;
	font-weight: 400;
	font-style: italic;
}

::-moz-placeholder {
	color: $color-placeholder;
	font-weight: 400;
	font-style: italic;
}

:-ms-input-placeholder {
	color: $color-placeholder;
	font-weight: 400;
	font-style: italic;
}

::-webkit-input-placeholder {
	color: $color-placeholder;
	font-weight: 400;
	font-style: italic;
}