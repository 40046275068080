/* ==================================================
   Subscribe form & Offers section
   ================================================== */

.subscribe {
  .form-item--error-message {
    display: block;
    width: 100%;
    text-align: center;
    color: #ff8d8d;
  }

  .webform-confirmation__message {
    @include font-size(22px);
    color: #1fad23;
    background-color:#fff;
    font-weight: 700;
  }

  .messages__wrapper {
		[role="contentinfo"] { display: none; }
  }

  .form-email.error { border-color: #ff8d8d; }

  .error {
    :-moz-placeholder { color: #ff8d8d; }
    
    ::-moz-placeholder { color: #ff8d8d; }
    
    :-ms-input-placeholder { color: #ff8d8d; }
    
    ::-webkit-input-placeholder { color: #ff8d8d; }
  }

  .inner { display: block; }

  .form {
    text-align: center;

    .inner {
      background-size: cover;
      background-position: top left;
      background-repeat: no-repeat;
    }
  }
  
  .form__item,
  .form-item,
  .form-actions {
    @include breakpoint(medium) { max-width: 280px; }
  }

  .banner__link {
    display: inline-block;
    @include font-size(12px);

    &:after {
      transform: scale(0.45);
      margin-top: -14px;
      margin-left: -5px;
    }
  }
}