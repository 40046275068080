/* ==================================================
   Error pages
   ================================================== */

.page--error{
  .section--articles .article__title{
    max-width: 300px;
    font-size: 19px;
  }
  .list--articles.flex-row li{
    max-width: 300px;
  }

  .list--articles.flex-row {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

.errorpage {
  + .subscribe--boxed {
    @include breakpoint(medium) { margin-top: 0; }
  }
  margin-bottom:30px;
  margin-top: 40px;
}

.errorpage__header {
  height: 600px;
  background-image:url(../images/nf_desktop.png);
  margin-top: 20px;

  @include breakpoint(medium) {
    height: auto;
    min-height:300px;
    padding-bottom: 30px;
    margin-top: 20px;
    background-image: url(../images/nf_mobile.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 100%;
  }
}

.errorpage__title {
  padding-top: 120px;
  padding-left:30px;
  @extend %title;
  @include font-size(25px);
  color: #000;
  line-height: 1.2;

  @include breakpoint(medium) { 
    padding-top:50px;
    @include font-size(20px); 
  }
  a{
    text-decoration: underline;
  }
}

.errorpage__image {
  display: block;
  width: 420px;
  height: 180px;
  margin: 40px auto 30px;

  background: {
    image: url('../images/404.jpg');
    size: cover;
    repeat: no-repeat;
  }

  @include breakpoint(medium) {
    width: 190px;
    height: 75px;
  }
}

.errorpage__text {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  @include font-size(15px);
  font-weight: 700;
  color: $color-text-1;
  line-height: 1.2;
  max-width: 940px;

  @include breakpoint(medium) {
    @include font-size(13px);
    font-weight: 400;
  }

  a { font-weight: 700; }

  a,
  a:hover,
  a:visited,
  a:active {
    color: $color-text-1;
    padding-bottom: 1px;
    border-bottom: 1px solid currentColor;
  }
}

